<template>
  <div>
    <div v-for="(file,index) in files" class="file-item" @click="preview(file)">
      <span class="blue file-item_name" v-ellipsis-text:middle>{{file.name}}</span>
      <span v-if="canDelete" class="del-icon" @click.stop="delFile(file)"><i class="el-icon-close fontBold"></i></span>
    </div>
    <div class="preview-wrapper">
      <viewer ref="pdfListViewer" class="preview"  :images="pdfPhotos">
        <img v-for="(src,index)  in pdfPhotos" :src="src" :key="index">
      </viewer>

      <div  class="wordfile" v-if="wordFileUrl" @click="closeIframe">
        <iframe :src="wordFileUrl" width='60%' height='90%' frameborder='0'/>
      </div>
    </div>
  </div>

</template>

<script>
import {previewPDF} from "../utils/utils";
import {deleteFIle} from "@/services/check";
import ellipsisText from '@/directive/ellipsis-text'

export default {
  name: "list-file",
  props:{
    files:{
      type:Array,
      default(){
        return []
      }
    },
    canDelete:{
      type:Boolean,
      default:false
    }
  },
  directives:{
    ellipsisText
  },
  data(){
    return {
      wordFileUrl:'',
      pdfPhotos:[]
    }
  },
  methods:{
    //  删除附件
    delFile(file){
      this.$confirm('确定要删除此附件?','提示',{
        type:'warning'
      }).then(()=>{
        deleteFIle({
          fileName:file.name,
          fileSize:file.size,
          fileUrl:file.url,
          id:file.id,
          isPicturePreview:file.isPicturePreview}).then(res=>{
          this.$message({
            type:'success',
            message:'删除成功'
          })
         this.$emit('delFile',file)
        })
      })
    },

    preview(file) {
      const fileType = file.url.substr(file.url.lastIndexOf(".")+1)
      // 如果是pdf 分图预览
      if (fileType=='pdf'&&file.isPicturePreview) {  // 预览pdf
        previewPDF(file).then(res=>{
          this.pdfPhotos = res;
          this.$refs.pdfListViewer.$viewer.show()
        })
      } else if(['doc','docx'].includes(fileType)) {
        this.wordFileUrl = "https://view.officeapps.live.com/op/view.aspx?src="+encodeURIComponent(file.url)
      }else if(['jpg','jpeg','png'].includes(fileType)){
        this.pdfPhotos = [file.url];
        this.$refs.pdfListViewer.$viewer.show()
      }
    },

    closeIframe(){
      this.wordFileUrl = ''
    }
  }
}
</script>

<style scoped lang="scss">
.file-item{
  cursor: pointer;
  .file-item_name{
    white-space: nowrap;
    max-width: 200px;
    display: inline-block;
    overflow: hidden;
  }
  .del-icon{
    padding:0 5px;
    color: red;
  }
}
.wordfile{
  position: fixed;
  left: 0;
  right:0;
  top:0;
  bottom: 0;
  z-index:99;
  background-color: rgba(0, 0, 0, 0.2);
  iframe{
    margin-left: 20%;
    margin-top: 2%;
  }
}
.preview {
  display: none;
}
</style>
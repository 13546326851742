<template>
  <div class="dynamic-form-search">
    <div class="search-content searchBox">
      <div
        class="search-item searchItem"
        v-for="(item, index) of _value.tableSearch"
        :key="index"
      >
        <span>{{ item.label }}：</span>
        <template v-if="item.options">
          <el-select
            v-model="item.value"
            :multiple="item.id.indexOf('multiple')>-1 || item.id.indexOf('dateMonths')>-1"
            collapse-tags
            placeholder="请选择"
            size="medium"
            clearable
            filterable
          >
            <el-option
              v-for="(e, i) of item.options"
              :key="i"
              :label="e.value"
              :value="e.id"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <el-input
            type="text"
            placeholder="请输入"
            size="medium"
            clearable
            v-model="item.value"
          ></el-input>
        </template>
      </div>
      <div class="search-item searchItem" v-if="hasBPM">
        <span>流程编号：</span>
        <el-input
            type="text"
            placeholder="请输入"
            size="medium"
            clearable
            v-model="_value.processNumber"
          ></el-input>
        <span>审核状态：</span>
        <el-select size="medium" v-model="_value.status">
          <el-option
            v-for="item in statusArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="search-item searchItem">
        <span>日期：</span>
        <el-date-picker
        style="height: 36px;line-height: 36px;width: 350px"
          v-model="_value.createTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="search-item searchItem">
        <div style="margin-left: 16px">
          <el-button
            type="primary"
            class="btn-search"
            @click="search()"
            size="medium"
            >查询</el-button
          >
          <el-button class="btn-export" type="primary" @click="exportTable" size="medium"
          >导出</el-button
          >
          <el-button class="btn-reset" @click="reset" size="medium"
            >重置</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DynamicFormSearch",
  props: {
    value: {
      type: Object,
      default() {
        return { tableSearch: [], status: "" };
      },
    },
    hasBPM:{
      type:Boolean,
      value:false
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    reset() {
      this._value.tableSearch.forEach((e) => {
         e.value && (e.value = new e.value.constructor().valueOf())
      });
      this._value.status = "";
      this._value.processNumber = "";
      this._value.createTime = [];
      this.$emit("search");
    },
    exportTable(){
      this.$emit('export')
    }
  },
  computed: {
    statusArr() {
      return [
              {label:'全部',value:''},
              {label:'进行中',value:0},
              {label:'已完成',value:1},
              {label:'已驳回',value:2},
              {label:'已撤销',value:3},
              {label:'已终止',value:5}];
    },
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-range-separator {
  line-height: 30px !important;
}
.dynamic-form-search {
  .search-item {
    margin-left: 16px;
    & > span {
      width: initial;
    }
  }
  .el-table {
    flex: 1;
    overflow-y: auto;
  }
  .el-input,
  .el-select {
    width: 220px;
  }
  .flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .inline-flex {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .inline-block {
    display: inline-block;
  }
}
</style>